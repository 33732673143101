
import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List } from "linq-collections";
import { vuex } from "@/store";
import { signalrEventBus } from '@/main';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// component
import CompImage from '@/components/ExerciseComponents/CompImage.vue';
import CompVideo from '@/components/ExerciseComponents/CompVideo.vue';
import CompPdf from '@/components/ExerciseComponents/CompPdf.vue';
// interfaces
import { BundleApprovalVm } from "@/interfaces/BundleApprovalVm";
import { BundleApprovalChatVm } from "@/interfaces/BundleApprovalChatVm";
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import moment from "moment";

//const auth = namespace("auth");
//const globals = namespace("globals");

@Component({
  components: {
    CompImage,
    CompVideo,
    CompPdf
  }
})
export default class ApprovalChatDlg extends Vue {
  @PropSync("showDialog") syncedShowDialog!: boolean;
  @Prop() approval!: BundleApprovalVm;

  busyOnSend = false;
  busyOnDelete = false;
  newMessage: string = "";
  chatMessages: BundleApprovalChatVm[] | null = null;
  visualViewportHeight = 0;
  chatImage: string | null = null;

  // @auth.Getter isAdmin: any;
  // @auth.Getter isOrgAdmin: any;
  // @auth.Getter isGroupAdmin: any;

  $refs!: {
    chattextfield: any;
  };

  mounted() {
    this.setWindowHeight();
  }

  @Watch("syncedShowDialog")
  async onShowDialogChanged(val: boolean) {
    if (this.syncedShowDialog) {
      // On dialog open
      this.chatMessages = null;
      this.chatImage = null;
      await this.updateView();
      await this.$l3rnOnlineHub.ensureConnection();

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closing
      clearAllBodyScrollLocks();
    }
  }

  created() {
    // window.addEventListener("resize", this.setWindowHeight);
    window.visualViewport?.addEventListener("resize", this.setWindowHeight);
    signalrEventBus.$on('updateApprovalChat', this.onUpdateApprovalChat);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.setWindowHeight);
    signalrEventBus.$off('updateApprovalChat', this.onUpdateApprovalChat);
    clearAllBodyScrollLocks();
  }

  // Event listener
  setWindowHeight() {
    // this.visualViewportHeight = window.innerHeight;
    this.visualViewportHeight = window.visualViewport?.height ?? 0;
    // this.$refs.chattextfield.focus();
    this.$globalHelper.animateScrollToBottom("chatContent", 500);
  }

  async onUpdateApprovalChat() {
    // console.log("ApprovalChatDlg.vue: onUpdateApprovalChat()");
    await this.loadChatList();
  }

  async updateView() {
    await this.loadChatList();
  }

  async loadChatList() {
    if (this.syncedShowDialog == false || !this.approval)
      return;

    let chatMessages = await rest.url("bundleApproval/getApprovalChatList")
      .query({ bundleId: this.approval.exBundleId })
      .get();

    // transfomr \n to <br>
    chatMessages.forEach(m => m.message = (m.message as string).replace("\n", "<br>"));
    this.chatMessages = chatMessages;

    this.$emit("update:approvalChatDlg");
    this.$globalHelper.animateScrollToBottom("chatContent", 800);
  }

  async sendMessage() {
    if (!this.approval || !this.chatMessages)
      return;

    let params = <ParamDictionary>{dictionary: {}};
    params.dictionary!["BundleId"] = this.approval.exBundleId.toString();
    params.dictionary!["Message"] = this.newMessage;
    params.dictionary!["Image"] = this.chatImage ?? "";

    this.busyOnSend = true;

    await rest.url("bundleApproval/sendApprovalChatMessage")
      .post(params)
      .then((response) => {
        // this.$emit("updateView:ApprovalChatDlg");
        this.newMessage = "";
        this.chatImage = null;
        this.updateView();
        this.$refs.chattextfield.focus();
      })
      .finally(() => {
        this.busyOnSend = false;
      });
  }

  async deleteMessage(message: BundleApprovalChatVm) {
    this.busyOnDelete = true;
    await rest.url("bundleApproval/deleteApprovalChatMessage")
      .query({ messageId: message.id })
      .delete()
      .then((response) => {
        this.updateView();
      })
      .finally(() => {
        this.busyOnDelete = false;
      });
  }

  pasteFunction(pasteEvent, callback) {
    let img = navigator.clipboard.read()

    if(pasteEvent.clipboardData == false){
        if(typeof(callback) == "function") {
            console.log('Undefined ')
            callback(undefined);
        }
    };

    let items = pasteEvent.clipboardData.items as DataTransferItemList;

    if(items == undefined){
        if(typeof(callback) == "function") {
            callback(undefined);
            console.log('Undefined 2')
        }
    };

    for (let element of items) {
      // console.log(element);
      // console.log(element.type);
      if (element.type.indexOf("image") >= 0) {
        let imageFile = element.getAsFile();
        console.log("Mime type: ", imageFile?.type);
        if (!imageFile)
          return;

        const reader = new FileReader();
        reader.onload = async event => {
          if (!event.target)
            return;

          let img = new Image();
          img.onload = async () => {
            // Check the image dimensions
            let maxWidth = 1024;
            let maxHeight = 1024;
            let scale = 1;
            if (img.width > maxWidth || img.height > maxHeight) {
              console.log(`Image to large: ${img.width} x ${img.height}`);
              let scaleX = maxWidth / img.width;
              let scaleY = maxHeight / img.height;
              scale = Math.min(scaleX, scaleY);
            }

            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            if (!ctx) return;

            canvas.width = img.width * scale; // desired width
            canvas.height = img.height * scale; // desired height
            console.log("Scale: ", scale);
            console.log(`New image size: ${canvas.width} x ${canvas.height}`);

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            // this.chatImage = canvas.toDataURL(imageFile?.type);
            this.chatImage = canvas.toDataURL("image/jpeg"); // convert to jpeg for better compression
            this.$globalHelper.animateScrollToBottom("chatContent", 200);
          };

          let base64image: string = (event as any).target.result;
          // console.log(base64image);
          img.src = base64image;
        };

        reader.readAsDataURL(imageFile);
      }
    }
  }

  isImage(message: BundleApprovalChatVm) {
    if (!message.mediaPath)
      return false;
    if (message.mediaPath.endsWith(".jpeg"))
      return true;

    return false;
  }

  get dialogHeight() {
    if (this.$vuetify.breakpoint.xs) {
      let height = this.visualViewportHeight;
      return height + "px";
    }
    else
      return "";
  }

  get mobileContentClass() {
    if (this.$vuetify.breakpoint.xs)
      return "chatDialogFullScreen"

    return "";
  }

  messageColor(message: BundleApprovalChatVm) {
    // if (this.$vuetify.theme.dark) {
    //   if (message.isMine)
    //     return "#00ff0020";
    // }

    if (message.isMine)
      return "#00ff0020";
  }

  messageOffsetClass(message) {
    if (message.isMine)
      return "ml-4";

    return "mr-4";
  }

  onCancel() {
    clearAllBodyScrollLocks();
    this.syncedShowDialog = false;
  }

  toLocalDate(date: string): string {
    // let dateFormated = moment(item?.finishedByAdminDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    let dateFormated = moment(date).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return dateFormated;
  }
}

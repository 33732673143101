import { render, staticRenderFns } from "./ReviewProcessDlg.vue?vue&type=template&id=3a7bb061&scoped=true&"
import script from "./ReviewProcessDlg.vue?vue&type=script&lang=ts&"
export * from "./ReviewProcessDlg.vue?vue&type=script&lang=ts&"
import style0 from "./ReviewProcessDlg.vue?vue&type=style&index=0&id=3a7bb061&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a7bb061",
  null
  
)

export default component.exports

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, namespace } from "vuex-class";
import rest from '@/rest';
import router from '@/router';
import _ from "lodash";
import { List } from 'linq-collections';
import { vuex } from "@/store";
import { signalrEventBus } from '@/main';
import moment from "moment";

// components
import ReleaseProcessDlg from '@/components/BundleApproval/ReleaseProcessDlg.vue';
import ReviewProcessDlg from '@/components/BundleApproval/ReviewProcessDlg.vue';
import UserApprovalDlg from '@/components/BundleApproval/UserApprovalDlg.vue';
import ApprovalChatDlg from '@/components/BundleApproval/ApprovalChatDlg.vue';

// interfaces
import { ExCategory1 } from '@/interfaces/ExCategory1';
import { ExCategory2 } from '@/interfaces/ExCategory2';
import { ExCategory3 } from '@/interfaces/ExCategory3';
import { ParamDictionary } from '@/interfaces/ParamDictionary';
import { CategorySelectListsVm } from '@/interfaces/CategorySelectListsVm';
import { ParamGetExercises } from '@/interfaces/ParamGetExercises';
import { ExBundleGroupVM, ExBundleVM } from '@/interfaces/ExBundleGroupVM';
import { BundleApprovalVm } from '@/interfaces/BundleApprovalVm';
import { ExBundleAssignmentVM } from '@/interfaces/ExBundleAssignmentVM';

const auth = namespace("auth");
const ux = namespace("ux");
const globals = namespace("globals");
const approvalChat = namespace("approvalChat");

@Component({
  components: {
    ReleaseProcessDlg,
    ReviewProcessDlg,
    UserApprovalDlg,
    ApprovalChatDlg
  }
})
export default class BundleApprovalView extends Vue {
  @auth.Getter isAdmin: any;
  @auth.Getter isGroupAdmin: any;
  @auth.Getter isContentManager: any;
  @globals.Getter selectedCategory1!: ExCategory1 | null;
  @globals.Getter selectedCategory2!: ExCategory2 | null;
  @globals.Getter selectedCategory3!: ExCategory3 | null;

  @approvalChat.Action updateNewApprovalChatCount: any;

  showReleaseProcessDlg = false;
  showReviewProcessDlg = false;
  showUserApprovalDlg = false;
  showApprovalChatDlg = false;

  paramDir: ParamDictionary = { dictionary: {}};
  category1Items: ExCategory1[] = [];
  category2Items: ExCategory2[] = [];
  category3Items: ExCategory3[] = [];
  exBundleGroups: ExBundleGroupVM[] = [];
  selectedBundle: ExBundleVM | null = null;
  approvals: BundleApprovalVm[] | null = null;
  selectedApproval: BundleApprovalVm | null = null;
  reviews: ExBundleVM[] | null = null;

  busy = false;
  tab = 0;

  async mounted() {
    vuex.globals.setUser(null);
    await this.loadCategories("cat1", "");
    await this.updateView();
    await this.$l3rnOnlineHub.ensureConnection();
  }

  created() {
    signalrEventBus.$on('updateApprovalChat', this.onUpdateApprovalChat);
  }

  beforeDestroy() {
    signalrEventBus.$off('updateApprovalChat', this.onUpdateApprovalChat);
  }

  onUpdateApprovalChat() {
    if (this.showApprovalChatDlg)
      return;

    // console.log("BundleApprovalView.vue: onUpdateApprovalChat()");
    this.getApprovalList();
  }

  async updateView() {
    this.getApprovalList();
    if (this.isAdmin || this.isContentManager) {
      this.getGroupedExBundles();
      this.getBundleReviewList();
    }
  }

  async cat1Changed(cat1: ExCategory1) {
    // console.log("grade changed, get exercises")
    vuex.globals.setCategory1(cat1);
    await this.loadCategories("cat1", "");
    await this.getGroupedExBundles();
  }

  async cat2Changed(cat2: ExCategory2) {
    // console.log("grade changed, get exercises")
    vuex.globals.setCategory2(cat2);
    await this.loadCategories("cat2", "");
    await this.getGroupedExBundles();
  }

  async loadCategories(changeEvent: string, selectGuid: string): Promise<void> {
     if (this.paramDir == null || this.paramDir.dictionary == null)
      return;

    this.paramDir.dictionary["ChangeEvent"] = changeEvent;
    this.paramDir.dictionary["SelectGuid"] = selectGuid;
    // this.paramDir.dictionary["UserId"] = this.selectedUser.id!;
    // this.paramDir.dictionary["GroupId"] = this.selectedGroup.id.toString()!;
    this.paramDir.dictionary["Category1Id"] = this.selectedCategory1?.id.toString() ?? "0";
    this.paramDir.dictionary["Category2Id"] = this.selectedCategory2?.id.toString() ?? "0";
    this.paramDir.dictionary["Category3Id"] = this.selectedCategory3?.id.toString() ?? "0";

    await rest.url("exercises/loadAllCategories").post(this.paramDir)
    .then((result: CategorySelectListsVm) => {
      this.category1Items = result.category1Items!;
      vuex.globals.setCategory1(result.selectedCat1Item);

      this.category2Items = result.category2Items!;
      vuex.globals.setCategory2(result.selectedCat2Item);

      this.category3Items = result.category3Items!;
      vuex.globals.setCategory3(result.selectedCat3Item);
    })
  }

  showExerciseBundleByApproval(approval: BundleApprovalVm) {
    // this.selectedApproval = approval;
    let assignmentVm = <ExBundleAssignmentVM>{
      id: 0, // not needed
      exBundleId: approval.exBundleId,
      exBundle: approval.exBundle,
      userId: null,
      enabled: true
    }
    this.$store.commit('globals/setExBundleAssignment', assignmentVm);
    router.push("/exerciseBundle");
  }

  showExerciseBundleByBundle(bundle: ExBundleVM) {
    let assignmentVm = <ExBundleAssignmentVM>{
      id: 0, // not needed
      exBundleId: bundle.id,
      exBundle: bundle,
      userId: null,
      enabled: true
    }
    this.$store.commit('globals/setExBundleAssignment', assignmentVm);
    router.push("/exerciseBundle");
  }

  get selectedCat1ItemName() {
    if (!this.selectedCategory1)
      return "-";
    return this.selectedCategory1.name;
  }

  get selectedCat2ItemName() {
    if (!this.selectedCategory2)
      return "-";
    return this.selectedCategory2.name;
  }

  get criticalReviewCount() {
    if (!this.reviews)
      return 0;

    return new List(this.reviews).count(r => r.bundleReviewDueDateCritical);
  }

  get openApprovalCount() {
    if (!this.approvals)
      return 0;

    return new List(this.approvals).count(a => !a.approved);
  }

  async getApprovalList() {
    this.approvals = await rest.url("bundleApproval/getApprovalList").get();
    await this.updateNewApprovalChatCount();
  }

  async getBundleReviewList() {
    this.reviews = await rest.url("contentManager/getBundleReviewList").get();
    // await this.updateNewApprovalChatCount();
  }

  async getGroupedExBundles() {
    // let param: ParamGetExercises = {
    //   // grade: this.grade,
    //   // subjectId: this.selectedSubject?.id ?? 0,
    //   category1Id: this.selectedCategory1?.id ?? 0,
    //   category2Id: this.selectedCategory2?.id ?? 0,
    //   category3Id: 0, // not necessary
    //   orgId: 0, // not relevant
    //   groupId: 0, // not relevant
    //   subGroupId: 0, // not relevant
    //   userId: null, // not relevant
    //   bundleId: 0, // not relevant
    //   enabled: false, // not relevant
    //   writeResults: false , // not relevant
    //   showUserView: false // not relevant
    // };
    let param: ParamDictionary = { dictionary: {}};
    param.dictionary!["Category1Id"] = (this.selectedCategory1?.id ?? 0).toString();
    param.dictionary!["Category2Id"] =  (this.selectedCategory2?.id ?? 0).toString();
    param.dictionary!["AddSpecialFolders"] = "false";

    this.exBundleGroups = await rest.url("contentManager/getGroupedExBundles").post(param);

    if (this.selectedBundle == null)
      return;

    let catName = this.selectedBundle.category3;
    if (catName == null) {
      this.selectedBundle = null;
      return;
    }

    let category = this.exBundleGroups.find(g => g.categoryName?.startsWith(catName!));
    if (category) {
      this.selectedBundle = category?.bundles?.find(b => b.guid == this.selectedBundle?.guid) ?? null;
    } else {
      this.selectedBundle = null;
    }
  }

  onBundleRelease(bundle: ExBundleVM) {
    this.selectedBundle = bundle;
    this.showReleaseProcessDlg = true;
  }

  onBundleReview(bundle: ExBundleVM) {
    this.selectedBundle = bundle;
    this.showReviewProcessDlg = true;
  }

  async onUserApproval(approval: BundleApprovalVm) {
    this.selectedApproval = approval;
    this.showUserApprovalDlg = true;
  }

  onBundleApprovalChat(approval: BundleApprovalVm) {
    this.selectedApproval = approval;
    this.showApprovalChatDlg = true;
  }

  approvalDateToLocalDate(approval: BundleApprovalVm): string {
    if (!approval?.date)
      return "";

    // let date = moment(item?.finishedByAdminDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    let date = moment(approval?.date).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return date;
  }

  hasReleaseDueDate(bundle: ExBundleVM): boolean {
    return !!bundle?.bundleReleaseDueDate;
  }

  releaseDateToLocalDate(approval: BundleApprovalVm): string {
    if (!approval?.exBundle?.bundleReleasedByDate)
      return "";

    // let date = moment(item?.finishedByAdminDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    let date = moment(approval?.exBundle?.bundleReleasedByDate).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return date;
  }

  bundleReleaseDueDateToLocalDate(bundle: ExBundleVM): string {
    if (!bundle?.bundleReleaseDueDate)
      return "";

    // let date = moment(item?.finishedByAdminDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    let date = moment(bundle?.bundleReleaseDueDate).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return date;
  }

  bundleReleaseDateToLocalDate(bundle: ExBundleVM): string {
    if (!bundle?.bundleReleasedByDate)
      return "";

    // let date = moment(item?.finishedByAdminDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    let date = moment(bundle?.bundleReleasedByDate).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return date;
  }

  bundleReviewDueDateToLocalDate(bundle: ExBundleVM): string {
    if (!bundle?.bundleReviewDueDate)
      return "";

    let date = moment(bundle?.bundleReviewDueDate).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return date;
  }

  bundleReviewDateToLocalDate(bundle: ExBundleVM): string {
    if (!bundle?.bundleReviewedByDate)
      return "";

    let date = moment(bundle?.bundleReviewedByDate).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return date;
  }

}

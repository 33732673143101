import { render, staticRenderFns } from "./ApprovalChatDlg.vue?vue&type=template&id=3bbb51bc&scoped=true&"
import script from "./ApprovalChatDlg.vue?vue&type=script&lang=ts&"
export * from "./ApprovalChatDlg.vue?vue&type=script&lang=ts&"
import style0 from "./ApprovalChatDlg.vue?vue&type=style&index=0&id=3bbb51bc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bbb51bc",
  null
  
)

export default component.exports
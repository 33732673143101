import { ActionTree, GetterTree, MutationTree } from "vuex";
import { Module } from "vuex";
import { createModule, mutation } from "vuex-class-component";
import { RootStore } from "../types";

const VuexModule = createModule({
  strict: false,
  namespaced: "ux",
});

export class Ux extends VuexModule {
  /* State */
  ready = false
  reserveSpaceForDrawer = false;
  /* Snackbar */
  snackbar = false;
  pending = false;
  success = false;
  failure = false;
  sbcontext = "info";
  sbtext = "";
  sbtimeout = 4000;
  /* Colors */
  darkTheme = false;
  // colorSuccess: "#64DD17",
  // colorInfo: "#039BE5",
  // colorWarning: "#F57C00",
  // colorError: "#F443364",
  // colorHeader1: "#FF9C10",
  // colorDivider1: "#FFC98A"
  colorSuccess = "white";
  colorInfo = "white";
  colorWarning = "white";
  colorError = "white";
  colorHeader1 = "#4089be";
  colorAccent = "#4089be";
  colorDivider1 = "white";
  dcPurple = "#ae7fd4";
  dcPink = "#da178f";
  dcBlue = "#007aff";
  dcYellow = "#dad617";
  dcRed = "#ff0000";
  colorListBackground = "white";
  // App Version
  appVersion = "v1.2.02";
  buildDate = "17.01.2025";

  get isReady() {
    return this.ready
  }

  @mutation setReady() {
    this.ready = true
  }

  @mutation ReserveSpaceForDrawer(isVisible: boolean) {
    this.reserveSpaceForDrawer = isVisible;
  }

  /* Snackbar */
  @mutation SB_PENDING(state) {
    this.pending = true;
  }
  @mutation SB_SUCCESS(data: any) {
    this.failure = false;
    this.sbcontext = "success";
    this.success = true;
    this.sbtext = data.message;
    this.snackbar = true;
    this.pending = false;
    if (data.timeout) this.sbtimeout = data.timeout;
    else this.sbtimeout = 2000;
  }
  @mutation SB_PRIMARY(data) {
    this.failure = false;
    this.sbcontext = "primary";
    this.success = true;
    this.sbtext = data.message;
    this.snackbar = true;
    this.pending = false;
    if (data.timeout) this.sbtimeout = data.timeout;
    else this.sbtimeout = 2000;
  }
  @mutation SB_FAILURE(data) {
    this.success = false;
    this.sbcontext = "error";
    this.failure = true;
    this.sbtext = data.message;
    this.snackbar = true;
    this.pending = false;
    if (data.timeout) this.sbtimeout = data.timeout;
    else this.sbtimeout = 0;
  }
  @mutation SB_WARNING(data) {
    this.success = false;
    this.sbcontext = "deep-orange darken-2";
    this.failure = true;
    this.sbtext = data.message;
    this.snackbar = true;
    this.pending = false;
    if (data.timeout) this.sbtimeout = data.timeout;
    else this.sbtimeout = 15000;
  }
  @mutation SB_UPDATE(value) {
    this.snackbar = value;
  }

  /* Colors */
  @mutation COLORS_DARK1() {
    this.darkTheme = true;
    this.colorSuccess = "#4CB944";
    this.colorInfo = "#246EB9";
    this.colorWarning = "#F57C00";
    this.colorError = "#F06543";
    this.colorHeader1 = "#FF9C10";
    this.colorAccent = "#FF9C10";
    this.colorDivider1 = "#FFC98A";
    this.colorListBackground = "#353535";
  }
  @mutation COLORS_DARK2() {
    this.darkTheme = true;
    this.colorSuccess = "#4CB944"; // "#8BC34A"; // "#11B59F";
    this.colorInfo = "#4E9DEC";
    this.colorWarning = "#F57C00";
    this.colorError = "#EE5C37";
    this.colorHeader1 = "#4089be";
    this.colorAccent = "#4089be";
    this.colorDivider1 = "#FFFFFF"; // "#11B59F";
    this.dcPurple = "#ae7fd4";
    this.dcPink = "#da178f";
    this.dcBlue = "#007aff";
    this.dcYellow = "#dad617";
    this.dcRed = "#ff0000";
    this.colorListBackground = "#353535";
  }
  @mutation COLORS_LIGHT() {
    this.darkTheme = false;
    this.colorSuccess = "#4CB944"; // "#66BB6A";
    this.colorInfo = "#4E9DEC"; // "#42A5F5";
    this.colorWarning = "#F57C00";
    this.colorError = "#F06543";
    this.colorHeader1 = "#4089be"; // "#4089be"; // "#FFA726";
    this.colorAccent = "#4089be"; // "#FFA726";
    this.colorDivider1 = "#FFFFFF"; // "#EF6C00";
    this.dcPurple = "#ae7fd4";
    this.dcPink = "#da178f";
    this.dcBlue = "#007aff";
    this.dcYellow = "#cac725";
    this.dcRed = "#ff0000";
    this.colorListBackground = "#FFFFFF";
  }
}
